import React from 'react'
import PropTypes from 'prop-types'
import { RightOutlined } from '@ant-design/icons'

const BankStatementCard = ({ title, description, action, duration }) => {
  return (
    <div className='bg-white shadow-md rounded-lg p-4 flex flex-col mb-4'>
      <div className='flex-grow mt-4'>
        <h2 className='font-semibold text-xl text-newBlack'>{title}</h2>
        <div className='flex justify-between mt-2'>
          <p className='text-xs text-newGray2'>{description}</p>
          <span className='text-blue-500'>
            <RightOutlined className='text-newOrange' />
          </span>
        </div>
        {duration && <p className='text-xs text-newGray2 mt-2'>{duration} Month Statement</p>}
      </div>

      {/* Divider Line */}
      <div className='border-t border-gray-300 mt-10 mb-4 w-full' />
      <div className='flex justify-between w-full'>
        <span className='font-medium text-xs text-newGray2'>Bank statement</span>
        <button
          onClick={action}
          className='font-semibold text-base text-newGreen hover:underline focus:outline-none'
        >
          View Bank Statement
        </button>
      </div>
    </div>
  )
}

BankStatementCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.func,
  duration: PropTypes.string
}

export default BankStatementCard
