import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'

const DropDownButton = ({ title, options, image, showTitle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState)
  }

  return (
    <div className={`mt-1  ${isOpen ? 'pb-28' : ''} `} style={{ position: 'relative' }}>
      <div className=' flex items-center py-3 pl-6 text-black text-xl font-extrabold hover:bg-gray-200'>
        <img alt='logo' width='20' height='20' className='inline-block ' src={image} />
        <button onClick={toggleDropdown} className={`${!showTitle && 'hidden'} px-2`}>
          <span>{title}</span>
          <span className='ml-2 '>
            {isOpen ? (
              <UpOutlined className='text-gray-400' />
            ) : (
              <DownOutlined className='text-gray-400 h-4 w-4' />
            )}
          </span>
        </button>
      </div>
      {isOpen && (
        <div className='absolute top-12 mt-2 bg-white shadow- rounded-m z-20'>
          {options.map((option, index) => (
            <NavLink
              to={{
                pathname: option.route || option.url || option.path,
                state: { type: option.type }
              }}
              key={option.id || index}
              activeClassName='active-nav-link border-r-8 border-blue-700'
              className='block text-black text- w-full px-5 py-2'
            >
              {option.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  )
}

DropDownButton.propTypes = {
  options: PropTypes.bool.isRequired,
  title: PropTypes.func.isRequired,
  image: PropTypes.any.isRequired,
  showTitle: PropTypes.bool.isRequired
}

export default DropDownButton
