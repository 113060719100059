import React from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import RefreshToken from './RefreshToken'
import { checkPrivilages } from '../../utils/helpers'
import { dispatch } from '../../redux/store'

const AdminRoute = ({ component: Component, privileges, ...rest }) => {
  const history = useHistory()
  const user = useSelector(({ auth }) => auth.authUser)

  const now = moment().unix()

  const { permissions } = useSelector(({ auth }) => auth.authUser)

  return (
    <Route
      {...rest}
      render={props => {
        const expTokenTime = user.userInfo?.exp
        const refreshToken = user?.refreshToken

        if (
          (!expTokenTime || expTokenTime <= now) &&
          (!refreshToken?.token || refreshToken?.details?.exp < now)
        ) {
          // not logged in so redirect to login page with the return url
          dispatch({ type: 'auth/logout' })
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (user?.userInfo?.is_admin === 0) {
          // role not authorized so redirect back where it came from
          dispatch({ type: 'auth/logout' })
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
        // TODO: FIX THESE ISSUES HERE ONCE HOME

        const isAllowed = checkPrivilages(privileges, permissions)
        if (!isAllowed) {
          return history.goBack()
        }

        // authorized so return component
        return (
          <>
            <RefreshToken />
            <Component {...props} />
          </>
        )
      }}
    />
  )
}

AdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.object,
  privileges: PropTypes.array
}

export default AdminRoute
