import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spin, notification } from 'antd'
import BankStatementCard from './BankStatementCard'
import TotalFundModal from '../modals/TotalFundModal'
import BankStatementModal from '../modals/BankStatementModal'
import { adminServices } from '../../../../services'
import UpdateBankStatementModal from '../modals/UpdateBankStatementModal'

const BankStatement = () => {
  const [isTotalFundModalOpen, setIsTotalFundModalOpen] = useState(false)
  const [isBankStatementModalOpen, setIsBankStatementModalOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [fetchBankStatementLoading, setFetchBankStatementLoading] = useState(true)
  const [bankStatement, setBankStatement] = useState(null)
  const [bankStatementMain, setBankStatementMain] = useState(null)
  const [bankStatementList, setBankStatementList] = useState([])
  const [isUpdateBankStatementModalOpen, setIsUpdateBankStatementModalOpen] = useState(false)

  const details = useSelector(({ applications }) => applications.appDetails)
  const loading = useSelector(({ loading }) => loading.effects.applications.applicationDetail)
  const bankStatementsOnly = bankStatementList?.filter(item => item?.type === 'bank_statement')

  const fetchBankStatements = async () => {
    if (details?.approved_data?.approved_loan_id) {
      try {
        const { data } = await adminServices.getRiskAnalysisLoanDocuments(
          details?.approved_data?.approved_loan_id
        )
        setBankStatementList(data?.data?.documents)
      } catch (error) {
        console.error('Error fetching bank statements:', error)
      } finally {
        setFetchBankStatementLoading(false)
      }
    }
  }

  const handleUploadBankStatement = async formData => {
    setUploading(true)
    try {
      const approvedLoanId = details?.approved_data?.approved_loan_id
      const { data } = await adminServices.extractContentFromBankStatement(formData, approvedLoanId)
      setBankStatement(data?.data)
      if (data.success) {
        notification.success({
          message: 'Success',
          description: 'Bank statement uploaded successfully'
        })
        return true
      } else {
        notification.error({
          message: 'Error',
          description: data.message || 'Failed to upload bank statement'
        })
        return false
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'Failed to upload bank statement'
      })
      return false
    } finally {
      setUploading(false)
    }
  }

  const handleDocumentSubmissionComplete = async () => {
    await fetchBankStatements()
  }

  const handleUpdateBankStatement = async formData => {
    try {
      await fetchBankStatements()
    } catch (error) {
      console.error('Error updating bank statement:', error)
    }
  }

  const handleCloseBankStatementModal = () => {
    setIsBankStatementModalOpen(false)
    setBankStatement(null)
  }

  const handleCloseUpdateBankStatementModal = () => {
    setIsUpdateBankStatementModalOpen(false)
    setBankStatement(null)
  }

  useEffect(() => {
    fetchBankStatements()
  }, [details])

  if (loading || fetchBankStatementLoading) {
    return (
      <div className='flex mt-4 ml-2'>
        <Spin size='default' /> <p className='ml-3'>Please wait ...</p>
      </div>
    )
  }

  return (
    <div className='flex w-full flex-col p-4'>
      {/* Bank Statement Buttons */}
      <div className='place-items-center justify-end mr-8 flex mb-4'>
        <button
          onClick={() => {
            setIsBankStatementModalOpen(true)
            setBankStatement(null)
          }}
          className='text-vittasBlue focus:outline-none'
        >
          Upload Bank Statement
        </button>
        <button
          onClick={() => setIsTotalFundModalOpen(true)}
          className='border bg-white border-vittasBlue text-vittasBlue px-4 py-2 ml-4 rounded focus:outline-none'
        >
          Total Fund Available
        </button>
      </div>

      {/* Bank Statements Grid */}
      <div className='w-full mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {bankStatementsOnly?.length === 0 && (
          <div className='col-span-3 text-center text-gray-500 py-8'>
            No bank statements available
          </div>
        )}
        {bankStatementsOnly
          ?.filter(item => item?.type === 'bank_statement')
          ?.map((statement, index) => (
            <BankStatementCard
              key={index}
              title={statement?.bank_name ?? 'Unknown'}
              description={statement?.account_number ?? 'Unknown'}
              fileUrl={statement?.file_url}
              duration={statement?.duration}
              action={() => {
                setIsUpdateBankStatementModalOpen(true)
                setBankStatementMain(statement)
              }}
            />
          ))}
      </div>

      {/* Bank Statement Modal */}
      <BankStatementModal
        showModal={isBankStatementModalOpen}
        setShowModal={handleCloseBankStatementModal}
        onSubmit={handleUploadBankStatement}
        onDocumentSubmissionComplete={handleDocumentSubmissionComplete}
        uploading={uploading}
        bankStatement={bankStatement}
        approvedLoanId={details?.approved_data?.approved_loan_id}
        loanId={details?.loan_id}
      />

      <UpdateBankStatementModal
        showModal={isUpdateBankStatementModalOpen}
        setShowModal={handleCloseUpdateBankStatementModal}
        onSubmit={handleUpdateBankStatement}
        uploading={uploading}
        bankStatement={bankStatementMain}
        approvedLoanId={details?.approved_data?.approved_loan_id}
        loanId={details?.loan_id}
        fetchBankStatements={fetchBankStatements}
      />

      {/* Total Fund Modal */}
      <TotalFundModal
        showModal={isTotalFundModalOpen}
        setShowModal={setIsTotalFundModalOpen}
        bankStatementList={bankStatementsOnly}
        approvedLoanId={details?.approved_data?.approved_loan_id}
      />
    </div>
  )
}

export default BankStatement
