import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import RiskManagementTabComponent from './RiskManagementTabComponent'
import ReviewTable from './review/ReviewTable'
import ApprovedTable from './approved/ApprovedTable'
import DeclinedTable from './declined/DeclinedTable'
import { Applications } from '../../../services'
import { notification } from 'antd'
import { transformBusinessInformation } from '../../../utils/helpers'

const RiskManagement = () => {
  const tabs = ['Review', 'Risk Approved', 'Risk Declined']
  const [loading, setLoading] = useState({
    review: true,
    approved: true,
    declined: true
  })
  const [data, setData] = useState({
    review: [],
    approved: [],
    declined: []
  })

  const transformApplicationData = (applications, type) => {
    if (!Array.isArray(applications)) return []

    return applications.map((app, index) => {
      // Transform business information first
      const businessInfo = transformBusinessInformation(app, index)

      const baseTransform = {
        id: app.loan_app_id,
        key: `${app.loan_app_id}-${index}-${type}-${Math.random()}`,
        business_name: businessInfo.business_name,
        loan_amount_requested: app.loan_principal_amount_fixed
          ? app.loan_principal_amount_fixed
          : app?.loan_amount,
        loan_term: app?.loan_duration,
        interest_rate: app.loan_interest_rate,
        loan_type: app.loan_type ? app.loan_type : app?.business_loan_industry,
        loan_purpose: app.loan_purpose,
        approval_date: app.approval_date,
        disbursed_date: app.disbursed_date,
        next_interest_date: app.next_interest_date,
        remaining_principal_amount: app.remaining_principal_amount,
        principal_amount_paid: app.principal_amount_paid,
        total_months_remaining: app.total_months_remaining
      }

      return baseTransform
    })
  }

  const fetchLoanApplications = async type => {
    setLoading(prev => ({ ...prev, [type.toLowerCase()]: true }))
    try {
      const response = await Applications.loanAppRiskAnalysis(type)
      const actualData = response?.data?.data

      // Transform the data before setting it
      const transformedData = transformApplicationData(actualData, type.toLowerCase())

      if (type === 'no_risk') {
        setData(prev => ({
          ...prev,
          review: transformedData || []
        }))
      }

      if (type === 'approved') {
        setData(prev => ({
          ...prev,
          approved: transformedData || []
        }))
      }

      if (type === 'declined') {
        setData(prev => ({
          ...prev,
          declined: transformedData || []
        }))
      }
    } catch (error) {
      notification.error({
        message: 'Error fetching applications',
        description: error?.response?.data?.message || error?.message
      })
    } finally {
      if (type === 'no_risk') {
        setLoading(prev => ({ ...prev, review: false }))
      }

      if (type === 'declined') {
        setLoading(prev => ({ ...prev, declined: false }))
      }

      if (type === 'approved') {
        setLoading(prev => ({ ...prev, approved: false }))
      }
    }
  }

  useEffect(() => {
    // Fetch data for all tabs on initial load
    fetchLoanApplications('no_risk')
    fetchLoanApplications('approved')
    fetchLoanApplications('declined')
  }, [])

  const contents = [
    // Review tab content
    <div key='Review'>
      <ReviewTable
        tableData={{
          data: data.review,
          loading: loading.review
        }}
      />
    </div>,

    // Approved tab content
    <div key='Risk Approved'>
      <ApprovedTable
        tableData={{
          data: data.approved,
          loading: loading.approved
        }}
      />
    </div>,

    // Declined tab content
    <div key='Risk Declined'>
      <DeclinedTable
        tableData={{
          data: data.declined,
          loading: loading.declined
        }}
      />
    </div>
  ]

  return (
    <DashboardLayout>
      <div className='p-4'>
        <h1 className='text-2xl font-bold mb-4'>Risk Management</h1>
        <RiskManagementTabComponent tabs={tabs} contents={contents} />
      </div>
    </DashboardLayout>
  )
}

export default RiskManagement
