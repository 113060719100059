/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import React from 'react'
import DropDown from '../../sharedComponents/drop-down/DropDown'
import IdsComponent from '../../sharedComponents/others/IdsComponent'

export const feedbackColumns = [
  {
    title: <span className='font-bold'>Customer ID</span>,
    dataIndex: 'user_id',
    key: 'user_id',
    datakey: 'User Id',
    render: user_id => <IdsComponent id={user_id} />,
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.user_id - b.user_id
  },
  {
    title: <span className='font-bold'>Feedback type</span>,
    dataIndex: 'type',
    datakey: 'Type',
    key: 'type',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.type - b.type
  },
  {
    title: <span className='font-bold'>Message</span>,
    dataIndex: 'description',
    datakey: 'Description',
    key: 'description',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.description - b.description
  },
  {
    title: <span className='font-bold'>Feedback Time Stamp</span>,
    dataIndex: 'feedback_time_stamp',
    datakey: 'Feedback time stamp',
    key: 'feedback_time_stamp',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => new Date(a.date) - new Date(b.date)
  },

  {
    title: <span className='font-bold capitalize'>Actions</span>,
    dataIndex: 'action',
    datakey: 'Action',
    key: 'action',
    render: () => {
      return (
        <DropDown>
          <div className='py-2'>
            <button className='flex ml-4  items-center'>
              <i className='fas fa-pencil-alt mr-2' aria-hidden='true' />
              Edit
            </button>
          </div>
        </DropDown>
      )
    }
  }
]
