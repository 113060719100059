/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

import { getColumnSearchProps } from '../../../sharedComponents/antSearch/search'
import IdsComponent from '../../../sharedComponents/others/IdsComponent'
import { checkPrivilages, formatCurrency } from '../../../utils/helpers'
import { ACTIONS } from '../../loans/constant'
import { INTEREST_TRACKER, VIEW_APPLICATION } from '../../applications/constant'
import * as roles from '../../../utils/roles'
import DropDown from '../../../sharedComponents/drop-down/DropDown'
import { notification } from 'antd'

const LOAN_APPLICATION = 'Back'
export const APPLY_FOR_LOAN = 'Apply For Loan'

export const closedLoansCollumn = (permissions, searchObject) => [
  {
    title: <span className='font-bold'>Business name</span>,
    datakey: 'business_name',
    dataIndex: 'buz_name',
    ...getColumnSearchProps({
      dataIndex: 'business_name',
      title: 'Business Name',
      ...searchObject
    }),
    key: 'business_name'
  },
  {
    title: <span className='font-bold'>Disbursed Date</span>,
    datakey: 'Disbursed Date',
    key: 'disbursed_date',
    dataIndex: 'created_at',
    render: disbursed_date => <span>{disbursed_date?.split(' ')[0]}</span>,
    sorter: (a, b) => new Date(a.disbursed_date) - new Date(b.disbursed_date)
  },
  {
    title: <span className='font-bold'>Intrest Rate</span>,
    datakey: 'Intrest Rate',
    key: 'loan_interest_rate',
    dataIndex: 'loan_interest_rate',
    render: loan_interest_rate => <span>{loan_interest_rate}</span>
  },
  {
    title: <span className='font-bold'>Loan Duration</span>,
    datakey: 'Loan Duration',
    key: 'loan_duration',
    dataIndex: 'loan_duration',
    render: loan_duration => <span>{loan_duration} Month</span>
  },
  {
    title: <span className='font-bold'>Loan Fixed Principal Amount</span>,
    datakey: 'Loan Amount',
    key: 'loan_principal_amount_fixed',
    dataIndex: 'loan_principal_amount_fixed',
    render: loan_principal_amount_fixed => (
      <span>₦{formatCurrency(loan_principal_amount_fixed)}</span>
    )
  },
  {
    title: <span className='font-bold'>Loan ID</span>,
    datakey: 'Loan ID',
    dataIndex: 'id',
    key: 'loan_app_id',
    ...getColumnSearchProps({
      dataIndex: 'id',
      title: 'Loan ID',
      ...searchObject
    }),
    render: loan_app_id => (
      <span>
        <IdsComponent id={loan_app_id} />
      </span>
    )
  },
  {
    title: <span className='font-bold capitalize'>{ACTIONS}</span>,
    datakey: '',
    key: 'action',
    render: data => (
      <DropDown>
        <Link
          to={`../application/${data.loan_app_id}`}
          style={{ outline: 0 }}
          className='w-full text-left nav-sm-link'
          title={
            !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
              ? 'You have no permission'
              : ''
          }
          disabled={!checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)}
        >
          <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
        </Link>
        <Link
          onClick={e => {
            if (!data.approved_loan_id) {
              e.preventDefault()
              notification.error({
                message: 'Invalid loan Id'
              })
            }
          }}
          to={`/interest-tracker/${data.approved_loan_id}?start=${
            data.disbursed_date?.split(' ')[0]
          }&duration=${data.loan_duration}`}
          style={{ outline: 0 }}
          className='w-full text-left nav-sm-link'
          title={
            !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
              ? 'You have no permission'
              : ''
          }
          disabled={!checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)}
        >
          <i className='far fa-eye mr-2' /> {INTEREST_TRACKER}
        </Link>
      </DropDown>
    )
  }
]

export { LOAN_APPLICATION }
