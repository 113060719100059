import React, { useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import InfoModal from '../../apply-for-loan/New-apply-loan/modal/InfoModal'
import PreviewModal from '../../apply-for-loan/New-apply-loan/modal/PreviewModal'
import {
  AOA,
  businessLicense,
  bankStatement,
  PharmaceuticalLicense,
  MOA,
  cac7,
  cac3,
  cac2
} from '../../../../sharedComponents/entries/constant'

const DocumentCard = ({
  title,
  fileUrl,
  infoText,
  handleInfoClick,
  setPreviewFileUrl,
  setModalVisible
}) => {
  if (!fileUrl) return null

  return (
    <div className='w-full'>
      <div className='flex gap-4'>
        <h2 className='text-base tracking-tight font-medium mb-4'>{title}</h2>
        <InfoCircleOutlined
          style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
          onClick={() => handleInfoClick(title, infoText)}
        />
      </div>
      <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
        <p className='text-sm px-6 mx-auto mb-2 text-center'>Document available</p>
        <button
          onClick={() => {
            setPreviewFileUrl(fileUrl)
            setModalVisible(true)
          }}
          className='text-blue-500 underline'
        >
          View Document
        </button>
      </div>
    </div>
  )
}

DocumentCard.propTypes = {
  title: PropTypes.string.isRequired,
  fileUrl: PropTypes.string,
  infoText: PropTypes.string.isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  setPreviewFileUrl: PropTypes.func.isRequired,
  setModalVisible: PropTypes.func.isRequired
}

DocumentCard.defaultProps = {
  fileUrl: ''
}

const CustomerDocs = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedCard, setSelectedCard] = useState({
    title: '',
    description: ''
  })
  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [previewFileUrl, setPreviewFileUrl] = useState('')

  const details = useSelector(({ applications }) => applications.appDetails)
  const loading = useSelector(({ loading }) => loading.effects.applications.applicationDetail)

  const handleInfoClick = (title, description) => {
    setSelectedCard({ title, description })
    setInfoModalVisible(true)
  }

  if (loading) {
    return (
      <div className='flex mt-4 ml-2'>
        <Spin size='default' />
      </div>
    )
  }

  return (
    <div className='p-4'>
      <div className=''>
        <div className=''>
          <div className='grid w-full max-w-5xl md:gap-14 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:mt-7'>
            {/* Article of Association */}
            <DocumentCard
              title='Article of Association'
              fileUrl={details?.business_aoa_doc}
              infoText={AOA}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* Pharmaceutical license */}
            <DocumentCard
              title='Pharmaceutical license'
              fileUrl={details?.business_super_pharmacist_lic}
              infoText={PharmaceuticalLicense}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* Business License */}
            <DocumentCard
              title='Business License'
              fileUrl={details?.business_license_doc}
              infoText={businessLicense}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* Bank Statement */}
            <DocumentCard
              title='Six (6) Month Bank Statement'
              fileUrl={
                details?.business_bank_statement?.[0]?.file_url || details?.business_bank_statement
              }
              infoText={bankStatement}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* MOA */}
            <DocumentCard
              title='Memorandum of Association (MOA)'
              fileUrl={details?.business_moa_doc}
              infoText={MOA}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* CAC Form 7 */}
            <DocumentCard
              title='CAC Form 7 (Directors Appointment)'
              fileUrl={details?.cac_document_seven}
              infoText={cac7}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* CAC Form 3 */}
            <DocumentCard
              title='CAC Form 3 (change of registered address)'
              fileUrl={details?.cac_document_three}
              infoText={cac3}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* CAC Form 2 */}
            <DocumentCard
              title='CAC Form 2 (Statement of share capital)'
              fileUrl={details?.cac_document_two}
              infoText={cac2}
              handleInfoClick={handleInfoClick}
              setPreviewFileUrl={setPreviewFileUrl}
              setModalVisible={setModalVisible}
            />

            {/* Distributor Vendor Ledger */}
            {details?.distributor_vendor_ledger && (
              <DocumentCard
                title='Distributor Vendor Ledger'
                fileUrl={details?.distributor_vendor_ledger}
                infoText='Distributor vendor ledger information'
                handleInfoClick={handleInfoClick}
                setPreviewFileUrl={setPreviewFileUrl}
                setModalVisible={setModalVisible}
              />
            )}

            {/* Sales Data */}
            {details?.sales_data && (
              <DocumentCard
                title='Sales Data'
                fileUrl={details?.sales_data}
                infoText='Sales data information'
                handleInfoClick={handleInfoClick}
                setPreviewFileUrl={setPreviewFileUrl}
                setModalVisible={setModalVisible}
              />
            )}

            {/* Agreement Document */}
            {details?.agreement_doc_url && (
              <DocumentCard
                title='Agreement Document'
                fileUrl={details?.agreement_doc_url}
                infoText='Agreement document information'
                handleInfoClick={handleInfoClick}
                setPreviewFileUrl={setPreviewFileUrl}
                setModalVisible={setModalVisible}
              />
            )}
          </div>
        </div>
      </div>

      {modalVisible && (
        <PreviewModal
          modalVisible={modalVisible}
          previewFileUrl={previewFileUrl}
          setModalVisible={setModalVisible}
        />
      )}

      <InfoModal
        visible={infoModalVisible}
        onCancel={() => setInfoModalVisible(false)}
        title={selectedCard.title}
        description={selectedCard.description}
      />
    </div>
  )
}

export default CustomerDocs
