import React from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'
import { DeclinedTableColumns } from '../declined-constants'

const DeclinedTable = ({ tableData }) => {
  return (
    <div className='z-40'>
      <Table
        columns={DeclinedTableColumns}
        dataSource={tableData.data}
        loading={tableData.loading}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          current: 1
        }}
      />
    </div>
  )
}

DeclinedTable.propTypes = {
  tableData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool
  }).isRequired
}

// Default props with loading set to true
DeclinedTable.defaultProps = {
  tableData: { data: [], loading: true }
}

export default DeclinedTable
