import { notification } from 'antd'
import { AuthApi, adminServices, AuthApi as authService } from '../../services'
import { reducerActions } from '../reducer'
import queryString from 'query-string'

const initialState = {
  exitingUsers: [],
  exitingAltUsers: [],
  paginatedUsers: [],
  pagination: {
    current_page: 1,
    size: 10,
    total_items: 0,
    total_pages: 0
  },
  isServerError: false
}

export const users = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async getAllAltUsers() {
      const query = queryString.stringify({
        showAll: true
      })

      dispatch.users.setError(false)
      try {
        const {
          data: { data }
        } = await adminServices.getExistingUsers(query, 'ignored', false)

        dispatch.users.setState({
          exitingAltUsers: data.users
        })
      } catch ({ response, message }) {
        dispatch.users.setError(response?.data)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
      }
    },
    async getAllusers(payload, rootState) {
      const queryParams = {
        page: payload?.page,
        search: payload?.search,
        startDate: payload?.startDate,
        endDate: payload?.endDate,
        showAll: payload?.showAll
      }

      const cleanParams = Object.fromEntries(
        Object.entries(queryParams).filter(([_, v]) => v != null)
      )

      const query = queryString.stringify(cleanParams)

      dispatch.users.setError(false)
      try {
        const {
          data: { data }
        } = await adminServices.getExistingUsers(
          query,
          cleanParams?.showAll ? 'ignored' : payload?.per_page
        )

        dispatch.users.setState({
          paginatedUsers: data.pagination ? data.users : [],
          exitingUsers: data.pagination ? [] : data.users,
          pagination: data.pagination
        })
      } catch ({ response, message }) {
        dispatch.users.setError(response?.data)
        notification.error({
          message: 'An error occurred',
          description: response?.data?.message || message
        })
      }
    },
    async updateUser(payload) {
      dispatch.users.setError(false)
      try {
        await adminServices.updateUser(payload)
        notification.success({
          message: 'User updated successfully',
          onClose: () => dispatch.users.getAllusers({ showAll: true })
        })
      } catch ({ response, message }) {
        dispatch.users.setError(response?.data)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
      }
    },
    async updateUserActiveStatus(payload, rootState) {
      dispatch.users.setError(null)
      try {
        const { data } = await AuthApi.updateUserStatus(payload)
        const { success, message } = data

        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }

        // Only update state if the API call was successful
        if (success) {
          const updatedUsers = rootState.users.exitingUsers.map(user =>
            user.id === payload.user_id
              ? { ...user, is_disabled: payload.enablement_status === 0 }
              : user
          )

          // Update the state with the new status
          dispatch.users.setState({
            exitingUsers: updatedUsers
          })
        }
      } catch (error) {
        const { response, message } = error
        dispatch.users.setError(response?.data)
        notification.error({
          message: 'An error occurred',
          description: response?.data?.message || message
        })
      }
    },
    async createUserTransactionPin(payload) {
      dispatch.users.setError(false)
      try {
        const response = await authService.createUserTransactionPin(payload)
        if (response?.status === 201) {
          notification.success({
            message: 'Transaction pin created successfully'
          })
          return true
        } else {
          return false
        }
      } catch ({ response, message }) {
        dispatch.users.setError(response?.data)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
        return false
      }
    },

    async updateUserTransactionPin(payload) {
      dispatch.users.setError(false)
      try {
        const response = await authService.updateUserTransactionPin(payload)
        if (response?.status === 200) {
          notification.success({
            message: 'Transaction pin updated successfully'
          })
          return true
        } else {
          return false
        }
      } catch ({ response, message }) {
        dispatch.users.setError(response?.data)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
        return false
      }
    },

    async verifyUserTransactionPin(payload) {
      dispatch.users.setError(false)
      try {
        const response = await authService.verifyUserTransactionPin(payload)
        if (response?.status === 200) {
          notification.success({
            message: 'Transaction pin verified successfully'
          })
          return true
        } else {
          return false
        }
      } catch ({ response, message }) {
        dispatch.users.setError(response?.data)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
        return false
      }
    },

    async reset() {
      await Promise.all([dispatch.users.setState(initialState)])
    }
  }),

  // https://rematchjs.org/docs/plugins/select/
  selectors: (slice, createSelector, hasProps) => ({
    getUsersNotAdmin() {
      return createSelector(
        slice, // shortcut for (rootState) => rootState.users
        users => users.exitingUsers?.filter(user => user?.is_admin <= 0)
      )
    },
    getAltUsersNotAdmin() {
      return createSelector(
        slice, // shortcut for (rootState) => rootState.users
        users => users.exitingAltUsers?.filter(user => user?.is_admin <= 0)
      )
    },
    getCurrentUserSelectedInCustomersLoan() {
      return createSelector(
        slice, // shortcut for (rootState) => rootState.users
        (rootState, props) => rootState?.loans.currentUserIDExpanded,
        (users, userId) => users?.exitingUsers?.find(user => user.id === userId)
      )
    },
    getUsersIsAdmin() {
      return createSelector(
        slice, // shortcut for (rootState) => rootState.users
        users => users.exitingUsers?.filter(user => user?.is_admin === 1)
      )
    },
    selectUsersErrors() {
      return slice(users => users.isServerError)
    }
  })
}
