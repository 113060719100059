/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Modal, notification, Select, DatePicker, Input } from 'antd'
import { Icon } from '@iconify/react'
import { LoadingOutlined } from '@ant-design/icons'
import { adminServices } from '../../../../services'
import moment from 'moment'

const bodyStyle = {
  padding: 0
}

// Function to disable dates before tomorrow
const disablePastDates = current => {
  return current && current < moment().endOf('day') // Disable dates before tomorrow
}

const DirectDebitFormModal = ({ visible, onCancel, bvn, userId, action }) => {
  const [loading, setLoading] = useState(false)

  // New state variables for the form
  const [debitOption, setDebitOption] = useState('')
  const [frequency, setFrequency] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [chargeDate, setChargeDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [amount, setAmount] = useState('')

  // Memoize the handleSubmission function
  const handleSubmission = useCallback(async () => {
    if (!bvn || !debitOption || !amount) {
      notification.error({
        message: 'Validation Error',
        description: 'Amount and debit option fields are required'
      })
      return
    }

    if (Number(amount) < 1000) {
      notification.error({
        message: 'Validation Error',
        description: 'Amount cannot be less than ₦1000'
      })
      return
    }

    if (debitOption === 'reoccurring') {
      if (!startDate || !endDate || !frequency) {
        notification.error({
          message: 'Validation Error',
          description: 'Start date, End date and frequency are required for recurring payments'
        })
        return
      }
    }

    if (debitOption === 'one-time') {
      if (!chargeDate) {
        notification.error({
          message: 'Validation Error',
          description: 'Start date, End date and frequency are required for recurring payments'
        })
        return
      }
    }

    setLoading(true)

    try {
      const payload = {
        user_id: userId,
        bvn,
        redirectUrl: `${window.location.origin}/direct-debit-consent-success`,
        amount,
        is_recurring: debitOption === 'reoccurring'
      }

      const oneTimePayload = {
        ...payload,
        charge_date: chargeDate || undefined
      }

      const recurringPayload = {
        ...payload,
        start_date: startDate || undefined,
        end_date: endDate || undefined,
        frequency: frequency || undefined
      }

      const response = await adminServices.directDebitInitialize(
        debitOption === 'reoccurring' ? recurringPayload : oneTimePayload
      )

      const link = response?.data?.data?.url
      notification.success({
        message: 'Success',
        description: 'Consent link generated successfully'
      })
      action(link)
    } catch (error) {
      notification.error({
        message: 'Something went wrong',
        description: error?.response?.data?.message || error?.message
      })
    } finally {
      setLoading(false)
    }
  }, [action, debitOption, frequency, startDate, endDate, amount])

  // Fetch users when modal becomes visible
  useEffect(() => {
    if (visible) {
      setDebitOption('')
      setFrequency('')
      setStartDate(null)
      setEndDate(null)
      setAmount('')
    }
  }, [visible])

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className='info-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          color='#b1b1ff'
          className='mt-4 mr-8 text-white text-4xl rounded-full bg-white'
        />
      }
      bodyStyle={bodyStyle}
      width={800}
    >
      <div className='modal-header bg-primary px-6 flex justify-between pb-4'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>Initiate Direct Debit</h2>
      </div>
      <div className='px-4 pb-8 mt-8'>
        <p className='font-bold text-primary'>
          Fill the information below to initiate direct debit for customer
        </p>
        <div className='flex flex-col gap-4 justify-center w-full'>
          <div className='grid grid-cols-2 gap-8'>
            <div>
              <p className='py-2 font-bold'>Select direct debit option</p>
              <Select
                value={debitOption}
                onChange={setDebitOption}
                placeholder='Select direct debit option'
                className='w-full'
                options={[
                  { label: 'Re-occurring debit', value: 'reoccurring' },
                  { label: 'One-time debit', value: 'one-time' }
                ]}
              />
            </div>
            {debitOption === 'reoccurring' ? (
              <div>
                <p className='py-2 font-bold'>Frequency</p>
                <Select
                  value={frequency}
                  onChange={setFrequency}
                  placeholder='Select frequency'
                  className='w-full'
                  options={[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Yearly', value: 'yearly' }
                  ]}
                />
              </div>
            ) : (
              <div />
            )}
          </div>
          {debitOption === 'reoccurring' ? (
            <div className='grid grid-cols-2 gap-8'>
              <div>
                <p className='py-2 font-bold'>Select Start Date</p>
                <DatePicker
                  value={startDate}
                  onChange={date => setStartDate(date)}
                  style={{ width: '100%' }}
                  disabledDate={disablePastDates}
                />
              </div>
              <div>
                <p className='py-2 font-bold'>Select End Date</p>
                <DatePicker
                  value={endDate}
                  onChange={date => setEndDate(date)}
                  style={{ width: '100%' }}
                  disabledDate={disablePastDates}
                />
              </div>
            </div>
          ) : (
            <div>
              <p className='py-2 font-bold'>Charge Date</p>
              <DatePicker
                value={chargeDate}
                onChange={date => setChargeDate(date)}
                style={{ width: '100%' }}
                disabledDate={disablePastDates}
              />
            </div>
          )}

          <div>
            <p className='py-2 font-bold'>Amount</p>
            <Input
              value={amount}
              onChange={e => setAmount(e.target.value)}
              placeholder='Enter amount'
              prefix='₦'
            />
          </div>

          <div className='flex justify-center'>
            <button
              onClick={handleSubmission}
              className='bg-primary rounded-md md:px-12 py-2 text-white outline border border-gray-300 flex items-center justify-center'
            >
              {loading ? <LoadingOutlined spin /> : 'Generate consent link'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

DirectDebitFormModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  bvn: PropTypes.string,
  userId: PropTypes.string,
  action: PropTypes.func.isRequired
}

// Wrap the entire component with React.memo
export default React.memo(DirectDebitFormModal)
