import React from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'
import { ApprovedTableColumns } from '../approved-constants'

const ApprovedTable = ({ tableData }) => {
  return (
    <div className='z-40'>
      <Table
        columns={ApprovedTableColumns}
        dataSource={tableData.data}
        loading={tableData.loading}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          current: 1
        }}
      />
    </div>
  )
}

ApprovedTable.propTypes = {
  tableData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool
  }).isRequired
}

// Default props with loading set to true
ApprovedTable.defaultProps = {
  tableData: { data: [], loading: true }
}

export default ApprovedTable
