import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import DTIAnalysis from '../components/DTIAnalysis'

const TotalFundModal = ({ showModal, setShowModal, bankStatementList, approvedLoanId }) => {
  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      destroyOnClose
      centered
      width={800}
    >
      <div className='header_modal mb-8 bg-primary w-full flex justify-between px-3 py-5'>
        <h2 className='text-white font-bold tracking-tighter text-2xl'>Total Fund Available</h2>
      </div>
      <DTIAnalysis
        bankStatementList={bankStatementList}
        approvedLoanId={approvedLoanId}
        setShowModal={setShowModal}
      />
    </Modal>
  )
}

// PropTypes validation
TotalFundModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  bankStatementList: PropTypes.array.isRequired,
  approvedLoanId: PropTypes.string.isRequired
}

export default TotalFundModal
