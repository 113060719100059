/* eslint-disable indent */
import { Formik, Form, Field } from 'formik'
import { Modal, Upload, notification } from 'antd'
import PropTypes from 'prop-types'
import { UploadOutlined } from '@ant-design/icons'
import { InputField } from '../../../../sharedComponents/input-field'
import { useState } from 'react'
import { adminServices } from '../../../../services'

const BankStatementModal = ({
  showModal,
  setShowModal,
  onSubmit,
  onDocumentSubmissionComplete,
  uploading,
  bankStatement,
  approvedLoanId,
  loanId
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [formRefreshKey, setFormRefreshKey] = useState(Math.random())
  const initialValues = {
    bank_name: bankStatement?.bank_name || '',
    account_number: bankStatement?.acc_no || '',
    opening_balance: bankStatement?.opening_balance || '',
    total_credit: bankStatement?.total_credit || '',
    total_debit: bankStatement?.total_debit || '',
    document_url: bankStatement?.url || '',
    file: null
  }

  const handleModalClose = () => {
    setIsSuccess(false)
    setFormRefreshKey(Math.random())
    setShowModal(false)
  }

  const handleSubmit = async (values, { setFieldValue }) => {
    const formData = new FormData()
    Object.keys(values).forEach(key => {
      if (values[key]) {
        formData.append(key, values[key])
      }
    })

    try {
      const statementProcessed = await onSubmit(formData)

      if (statementProcessed) {
        setIsSuccess(true)
        setFormRefreshKey(Math.random())
      }
    } catch (error) {
      // Clear the file if submission fails
      setFieldValue('file', null)
    }
  }

  const handleDocumentSubmission = async values => {
    try {
      const { data } = await adminServices.processRiskAnalysisDocument(
        approvedLoanId,
        bankStatement?.document_id,
        {
          bank_name: values.bank_name,
          opening_balance: values.opening_balance,
          total_credit: values.total_credit,
          total_debit: values.total_debit,
          acc_no: values.account_number
        }
      )

      if (data?.risk_analysis_id) {
        notification.success({
          message: 'Success',
          description: 'Bank statement added successfully'
        })
        await onDocumentSubmissionComplete()
        handleModalClose()
      } else {
        notification.error({
          message: 'Error',
          description: data.message || 'Failed to add bank statement'
        })
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'Failed to add bank statement'
      })
    }
  }

  return (
    <Modal
      open={showModal}
      onCancel={handleModalClose}
      footer={null}
      destroyOnClose
      centered
      width={800}
    >
      <div className='header_modal bg-primary w-full flex justify-between px-3 py-5'>
        <h2 className='text-white font-bold tracking-tighter text-2xl'>
          {bankStatement ? 'Add Bank Statement' : 'Upload Bank Statement'}
        </h2>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={isSuccess ? handleDocumentSubmission : handleSubmit}
        enableReinitialize
        key={formRefreshKey}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <div className='w-full my-8 px-4'>
              {!bankStatement ? (
                // Show upload component when no bank statement exists
                <div className='flex justify-start my-8'>
                  <Upload
                    beforeUpload={file => {
                      setFieldValue('file', file)
                      return false
                    }}
                    maxCount={1}
                  >
                    <button
                      type='button'
                      className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
                    >
                      <UploadOutlined /> Select Statement
                    </button>
                  </Upload>
                </div>
              ) : (
                // Show form fields when bank statement exists
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div className='px-2 mb-6 flex flex-col'>
                    <label className=' text-gray-600 font-medium'>Bank Name:</label>
                    <Field name='bank_name'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          placeholder='Enter bank name'
                          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
                        />
                      )}
                    </Field>
                  </div>

                  <div className='px-2 mb-6 flex flex-col'>
                    <label className=' text-gray-600 font-medium'>Account Number:</label>
                    <Field name='account_number'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          placeholder='Enter account number'
                          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
                        />
                      )}
                    </Field>
                  </div>

                  <div className='px-2 mb-6 flex flex-col'>
                    <label className=' text-gray-600 font-medium'>Opening Balance:</label>
                    <Field name='opening_balance'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          type='number'
                          placeholder='Enter opening balance'
                          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
                        />
                      )}
                    </Field>
                  </div>

                  <div className='px-2 mb-6 flex flex-col'>
                    <label className=' text-gray-600 font-medium'>Total Credit:</label>
                    <Field name='total_credit'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          type='number'
                          placeholder='Enter total credit'
                          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
                        />
                      )}
                    </Field>
                  </div>

                  <div className='px-2 mb-6 flex flex-col'>
                    <label className=' text-gray-600 font-medium'>Total Debit:</label>
                    <Field name='total_debit'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          type='number'
                          placeholder='Enter total debit'
                          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
                        />
                      )}
                    </Field>
                  </div>
                </div>
              )}
              {isSuccess && (
                <p className='text-red-500'>Confirm the information above before proceeding</p>
              )}

              {/* Add/Upload Statement Button */}
              <div className='flex justify-center mt-8'>
                {isSuccess ? (
                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
                  >
                    {isSubmitting ? 'Processing...' : 'Update Statement'}
                  </button>
                ) : (
                  <button
                    type='submit'
                    disabled={isSubmitting || uploading}
                    className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
                  >
                    {uploading
                      ? 'Extracting...'
                      : bankStatement
                      ? 'Add Statement'
                      : 'Upload Statement'}
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

BankStatementModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDocumentSubmissionComplete: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  bankStatement: PropTypes.object,
  approvedLoanId: PropTypes.string,
  loanId: PropTypes.string
}

export default BankStatementModal
