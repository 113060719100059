import React, { useState, useEffect } from 'react'
import { InputField } from '../../../../sharedComponents/input-field'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../../redux/store'
import { Spin } from 'antd'

const LoanDetails = () => {
  const [loanAmount, setLoanAmount] = useState('')
  const [loanInterest, setLoanInterest] = useState('')
  const [loanDuration, setLoanDuration] = useState('')
  const [loanPurpose, setLoanPurpose] = useState('')
  const [distributor, setDistributor] = useState('')
  const location = useLocation()
  const details = useSelector(({ applications }) => applications.appDetails)
  const loading = useSelector(({ loading }) => loading.effects.applications.applicationDetail)

  const handleInputChange = setter => event => {
    setter(event.target.value)
  }

  useEffect(() => {
    const getQueryParams = () => {
      const searchParams = new URLSearchParams(location.search)
      return {
        id: searchParams.get('id')
      }
    }

    const { id } = getQueryParams()

    if (id) {
      dispatch.applications.applicationDetail({ slug: id, isAdmin: true })
    }
  }, [dispatch])

  useEffect(() => {
    if (details) {
      setLoanAmount(details.loan_amount || '')
      setLoanInterest(details.loan_rate || '')
      setLoanDuration(details.duration_of_loan || '')
      setLoanPurpose(details.loan_purpose || '')
      setDistributor(details.distributor?.[0]?.amount || '')
    }
  }, [details])

  if (loading) {
    return (
      <div className='flex mt-4 ml-2'>
        <Spin size='default' />
      </div>
    )
  }

  return (
    <div className='flex flex-wrap'>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanAmount)}
          value={loanAmount}
          type='number'
          name='loanAmount'
          label='Loan Amount'
          placeholder='Enter loan amount'
          className='form-control bg-white'
          disabled
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanInterest)}
          value={loanInterest}
          type='number'
          name='loanInterest'
          label='Loan Interest (%)'
          placeholder='Enter loan interest rate'
          className='form-control bg-white'
          disabled
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanDuration)}
          value={loanDuration}
          type='number'
          name='loanDuration'
          label='Loan Duration (years)'
          placeholder='Enter loan duration'
          className='form-control bg-white'
          disabled
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanPurpose)}
          value={loanPurpose}
          type='text'
          name='loanPurpose'
          label='Loan Purpose'
          placeholder='Enter loan purpose'
          className='form-control bg-white'
          disabled
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setDistributor)}
          value={distributor}
          type='text'
          name='distributor'
          label='Distributor'
          placeholder='Enter distributor name'
          className='form-control bg-white'
          disabled
        />
      </div>
    </div>
  )
}

export default LoanDetails
