/* eslint-disable camelcase */
import React, { useState } from 'react'
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom'
import logo from '../../../images/logo.png'
import { SIDE_MENU, ADMIN_SIDE_MENU, SOCIAL, LOG_OUT } from './constants'
import { useSelector } from 'react-redux'
import { checkPrivilages } from '../../../utils/helpers'
import RightIcon from '../../../images/icons/right-icon.svg'
import LogoIcon from '../../../images/icons/LogoIcon.svg'
import { dispatch } from '../../../redux/store'
import DropDownButton from '../adminSideNavDropDown/sideNavDropDown'

const SideMenu = () => {
  const [open, setOpen] = useState(true)
  const [toggleInfo, setToggleInfo] = useState(true)
  const history = useHistory()
  const { permissions, userInfo } = useSelector(({ auth }) => auth.authUser)
  const handleLogout = async () => {
    await dispatch.auth.logout()

    history.push('/login')
  }

  const { pathname } = useLocation()

  return (
    <aside
      className={`${open ? 'w-72' : 'w-24'} admin-side-menu mr-4 duration-300 overflow-scroll`}
    >
      <div
        onClick={() => setOpen(!open)}
        className='absolute float-right flex justify-center items-center mb-16 -right-3 rounded-t-4 bottom-16 w-9 h-14 bg-gray-100 cursor-pointer'
      >
        <img src={RightIcon} className='w-8' onClick={() => setOpen(!open)} />
      </div>
      <div className='px-3 border-b-2 border-gray-200 pb-3'>
        <Link to='/'>
          <img src={open ? logo : LogoIcon} alt='logo' className='inline-block mt-5' />
        </Link>
      </div>

      <nav className='text-white text-base font-semibold pt-3'>
        {userInfo?.is_admin === 0 &&
          SIDE_MENU.map(({ id, path, url, title, image }) => (
            <NavLink
              key={id}
              to={url}
              activeClassName='active-nav-link border-r-8 border-blue-700'
              className={`side-menu-link mt-2  ${pathname === path ? 'bg-gray-200' : ''} ${
                Array.isArray(path) && path.includes(pathname) ? 'bg-gray-200' : ''
              }`}
            >
              <img src={image} alt='logo' width='20' height='20' className='inline-block mr-2' />
              <span className={`${!open && 'hidden'}`}>{title}</span>
            </NavLink>
          ))}
        {userInfo?.is_admin === 1 &&
          ADMIN_SIDE_MENU.map(({ id, path, url, title, image, privileges, options, type }) => {
            const isAllowed = checkPrivilages(privileges, permissions)
            return (
              isAllowed &&
              (type === 'dropdown' ? (
                <DropDownButton
                  key={id}
                  title={title}
                  options={options}
                  image={image}
                  showTitle={open}
                />
              ) : (
                <NavLink
                  key={id}
                  to={url}
                  title={isAllowed === false ? 'You have no permission' : ''}
                  disabled={!isAllowed}
                  activeClassName='active-nav-link border-r-8 border-blue-700'
                  className={`side-menu-link mt-1 ${pathname === path ? 'bg-gray-200' : ''} ${
                    Array.isArray(path) && path.includes(pathname) ? 'bg-gray-200' : ''
                  }`}
                >
                  <img
                    src={image}
                    alt='logo'
                    width='20'
                    height='20'
                    className='inline-block mr-2'
                  />
                  <span className={`${!open && 'hidden'}`}>{title}</span>
                </NavLink>
              ))
            )
          })}
        <div className={`${'hidden'} p-3`}>
          {userInfo?.is_admin === 1 ? (
            pathname === '/admin-disbursed' ? (
              ''
            ) : (
              <div className='bg-gray-100 p-2 rounded-lg'>
                <div className='flex justify-between'>
                  <i
                    onClick={() => setToggleInfo(!toggleInfo)}
                    className={`${
                      toggleInfo ? 'fa fa-times' : 'fas fa-chevron-down'
                    } text-lg text-gray-500 hover:text-gray-400 cursor-pointer`}
                  />
                </div>
              </div>
            )
          ) : (
            <div className='bg-gray-100 p-2 rounded-lg'>
              <div className='flex justify-between'>
                <i
                  onClick={() => setToggleInfo(!toggleInfo)}
                  className={`${
                    toggleInfo ? 'fa fa-times' : 'fas fa-chevron-down'
                  } text-lg text-gray-500 hover:text-gray-400 cursor-pointer`}
                />
              </div>
            </div>
          )}
        </div>
        <div className={`${'hidden'} mx-2`}>
          <ul className='flex justify-center mt-10'>
            {SOCIAL.map(({ id, path, title, li, icons }) => (
              <li key={id} className={li}>
                <a target='_blank' href={path} title={title} rel='noopener noreferrer'>
                  <i className={icons} />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <button onClick={handleLogout} className='nav-link-md text-red-800 ml-7 mt-5'>
          <i className='fas fa-sign-out-alt mr-3' />
          <span className={`${!open && 'hidden'}`}>{LOG_OUT}</span>
        </button>
      </nav>
    </aside>
  )
}

export default SideMenu
