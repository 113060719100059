import DropDown from '../../../sharedComponents/drop-down/DropDown'
import { formatCurrency } from '../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import LoanDetails from './components/LoanDetails'
import CustomerDocs from './components/CustomerDocs'
import BankStatement from './components/BankStatement'

const ActionColumn = record => {
  const history = useHistory()

  return (
    <DropDown>
      <span
        className='w-full text-left nav-sm-link text-blue-600 cursor-pointer'
        onClick={() =>
          history.push(
            `/review-details-page?id=${record?.record?.id}&businessName=${record?.record?.business_name}`
          )
        }
      >
        Show Details
      </span>
    </DropDown>
  )
}

export const ApprovedTableColumns = [
  {
    title: <span className='font-bold'>Business Name</span>,
    dataIndex: 'business_name',
    key: 'business_name'
  },
  {
    title: <span className='font-bold'>Loan Amount Requested</span>,
    dataIndex: 'loan_amount_requested',
    key: 'loan_amount_requested',
    render: amount => <span>₦{formatCurrency(amount)}</span>
  },
  {
    title: <span className='font-bold'>Loan Term</span>,
    dataIndex: 'loan_term',
    key: 'loan_term',
    render: loanTerm => <span>{loanTerm ? `${loanTerm}` : 'N/A'}</span>
  },
  {
    title: <span className='font-bold'>Interest Rate</span>,
    dataIndex: 'interest_rate',
    key: 'interest_rate',
    render: rate => <span>{rate ? `${rate}%` : 'N/A'}</span>
  },
  {
    title: <span className='font-bold'>Loan Type</span>,
    dataIndex: 'loan_type',
    key: 'loan_type'
  },
  {
    title: <span className='font-bold'>Loan Purpose</span>,
    dataIndex: 'loan_purpose',
    key: 'loan_purpose'
  },
  {
    title: <span className='font-bold'>Action</span>,
    key: 'action',
    render: (text, record) => <ActionColumn record={record} />
  }
]

export const ApprovedTabItems = [
  {
    label: 'Loan Details',
    key: '1',
    children: <LoanDetails />
  },
  {
    label: 'Customer Documents',
    key: '2',
    children: <CustomerDocs />
  },
  {
    label: 'Bank Statements',
    key: '3',
    children: <BankStatement />
  }
]
