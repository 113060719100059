/* eslint-disable no-unused-vars */
import * as roles from '../../../utils/roles'
import dashboardImg from '../../../images/icons/dashboard.svg'
import applicationsImg from '../../../images/icons/applications.svg'
import loansImg from '../../../images/icons/loans.svg'
import approvedImg from '../../../images/icons/approved-icon.svg'
import interestTrackerImg from '../../../images/icons/interest.svg'
import feedbacksImg from '../../../images/icons/feedbacks.svg'
import distributorImg from '../../../images/icons/distributor.svg'
import contactImg from '../../../images/icons/contact.svg'
import rolesImg from '../../../images/icons/roles.svg'
// import templateImg from '../../../images/icons/template.png'
import TransactionImg from '../../../images/icons/transaction.svg'
import BlacklistImg from '../../../images/icons/task-square.svg'
import MessagesImg from '../../../images/icons/direct-inbox.svg'
// import CustomerDirImg from '../../../images/icons/people.svg'
import HomeImage from '../../../images/icons/home.svg'
import NotificationImg from '../../../images/icons/bell.svg'
import ClosedLoanImg from '../../../images/close loan.svg'
import AdministrationImg from '../../../images/icons/administrator_line_icon.svg'
import sysLogsImg from '../../../images/icons/system-logs.png'
import { BLACKLIST } from '../../HomePage/constant'

const DASHBOARD = 'Dashboard'
const APPLICATIONS = 'Applications'
const LOANS = 'Loans'
const APPROVED = 'Approved Loans'
const CLOSED_LOANS = 'Closed Loans'
const INTEREST_TRACKER = 'Interest Tracker'
const TRANSACTIONS = 'Transactions'
const NOTIFICATION = 'Notifications'
const MESSAGE = 'Message'
// const CUSTOMERS = 'Legacy Customers Module'
const FINANCE = 'Finance'
// const Transactions = 'Transactions'
const ROLE = 'Role'
const HOME = 'Home'
const ADMINISTRATION = 'Administration'
const SYSTEM_LOGS = 'System Logs'
// const TEMPLATES = 'Templates'
const FEEDBACKS = 'Feedbacks'
const DIRECTORS = 'Directors'
const DISTRIBUTORS = 'Distributors'
const YOUTUBE = 'YouTube'
const TWITTER = 'twitter'
const FACEBOOK = 'facebook'
const LINKEDIN = 'linkedin'
const CUSTOMERSMODULE = 'Manage Customers'
const SAAS_CUSTOMER = 'Saas Customers'
const Leads = 'Leads'
const RISK_MANAGEMENT = 'Risk Management'
const DISBURSED_LOANS = 'Disbursed Loans'
// const UPLOAD_DOCS = 'Manage Customer Docs'
// const CUSTOMER_DIRECTOR = 'Customer Directors'
export const LOG_OUT = 'Log Out'

const customerdropdownoptions = [
  {
    id: 'sidebar-dropdown-1',
    path: '/admin-customers-module',
    url: '/admin-customers-module',
    title: CUSTOMERSMODULE,
    privileges: [roles.VIEW_USERS],
    image: contactImg
  },
  {
    id: 'sidebar-dropdown-2',
    path: '/admin-saas-customers',
    url: '/admin-saas-customers',
    title: SAAS_CUSTOMER,
    privileges: [roles.ADD_TERMINAL_ID],
    image: contactImg
  }
]
const loandropdownOptions = [
  {
    id: 'sidebar-loan-1',
    title: DISBURSED_LOANS,
    route: '/admin-disbursed',
    image: loansImg,
    url: '/admin-disbursed',
    privileges: [roles.VIEW_ADMINISTRATION]
  },
  {
    id: 'sidebar-loan-2',
    title: APPROVED,
    route: '/admin-approved',
    image: approvedImg,
    url: '/admin-approved',
    privileges: [roles.APPROVE_REJECTED_LOANS]
  },
  {
    id: 'sidebar-loan-3',
    title: CLOSED_LOANS,
    route: '/closed-loans',
    image: ClosedLoanImg,
    url: '/closed-loans',
    privileges: [roles.VIEW_ADMINISTRATION]
  }
]

export const ADMIN_SIDE_MENU = [
  {
    id: 'sidebar-1',
    path: '/admin-dashboard',
    url: '/admin-dashboard',
    title: DASHBOARD,
    privileges: [roles.VIEW_DASHBOARD],
    image: dashboardImg
  },
  {
    id: 'sidebar-2',
    type: 'dropdown',
    title: 'Customers',
    options: customerdropdownoptions,
    privileges: [roles.VIEW_ADMINISTRATION],
    image: contactImg
  },
  {
    id: 'sidebar-3',
    url: '/admin-pending',
    path: '/admin-pending',
    title: APPLICATIONS,
    privileges: [roles.VIEW_LOAN_APPLICATIONS],
    image: applicationsImg
  },
  {
    id: 'sidebar-4',
    path: '/risk-management',
    url: '/risk-management',
    title: RISK_MANAGEMENT,
    privileges: [roles.APPROVE_REJECTED_LOANS],
    image: interestTrackerImg
  },
  {
    id: 'sidebar-5',
    type: 'dropdown',
    title: 'Loans',
    options: loandropdownOptions,
    privileges: [roles.VIEW_ADMINISTRATION],
    image: loansImg
  },
  {
    id: 'sidebar-6',
    path: '/interest-tracker',
    url: '/interest-tracker',
    title: INTEREST_TRACKER,
    privileges: [roles.VIEW_INTEREST_TRACKER],
    image: interestTrackerImg
  },
  {
    id: 'sidebar-7',
    path: '/finance',
    url: '/finance',
    title: FINANCE,
    privileges: [roles.VIEW_USERS],
    image: TransactionImg
  },
  {
    id: 'sidebar-8',
    path: '/distributors',
    url: '/distributors',
    title: DISTRIBUTORS,
    privileges: [roles.VIEW_ADMINISTRATION],
    image: distributorImg
  },
  {
    id: 'sidebar-9',
    path: '/admin-roles',
    url: '/admin-roles',
    title: ROLE,
    privileges: [roles.VIEW_ADMINISTRATION, roles.VIEW_ROLES],
    image: rolesImg
  },
  {
    id: 'sidebar-10',
    path: '/admin-leads',
    url: '/admin-leads',
    title: Leads,
    privileges: [roles.VIEW_USERS],
    image: rolesImg
  },
  {
    id: 'sidebar-11',
    path: '/administration',
    url: '/administration',
    title: ADMINISTRATION,
    privileges: [roles.VIEW_ADMINISTRATION],
    image: AdministrationImg
  },
  {
    id: 'sidebar-12',
    path: '/system-logs',
    url: '/system-logs',
    title: SYSTEM_LOGS,
    privileges: [roles.VIEW_SYSTEM_LOGS],
    image: sysLogsImg
  },
  {
    id: 'sidebar-13',
    path: '/messages',
    url: '/messages',
    title: MESSAGE,
    privileges: [roles.VIEW_FEEDBACK],
    image: MessagesImg
  },
  {
    id: 'sidebar-14',
    path: '/feedbacks',
    url: '/feedbacks',
    title: FEEDBACKS,
    privileges: [roles.VIEW_FEEDBACK],
    image: feedbacksImg
  },
  {
    id: 'sidebar-15',
    path: '/blacklist',
    url: '/blacklist',
    title: BLACKLIST,
    privileges: [roles.VIEW_FEEDBACK],
    image: BlacklistImg
  }
]

export const SIDE_MENU = [
  { id: 0, path: '/dashboard', url: '/dashboard', title: HOME, image: HomeImage },
  {
    id: 1,
    url: '/applications/all-application',
    path: [
      '/applications/all-application',
      '/applications/application-in-review',
      '/applications/approved-application',
      '/applications/declined-application'
    ],
    title: APPLICATIONS,
    image: applicationsImg
  },
  { id: 2, path: '/directors', url: '/directors', title: DIRECTORS, image: contactImg },
  {
    id: 3,
    path: '/transactions',
    url: '/transactions',
    title: TRANSACTIONS,
    image: TransactionImg
  },
  {
    id: 4,
    path: '/documents',
    url: '/documents',
    title: 'Documents',
    image: TransactionImg
  },

  {
    id: 5,
    path: '/customer-notifications',
    url: '/customer-notifications',
    title: NOTIFICATION,
    image: NotificationImg
  },
  { id: 6, path: '/feedbacks', url: '/feedbacks', title: FEEDBACKS, image: feedbacksImg }
]

export const SOCIAL = [
  {
    id: 1,
    path: 'https://twitter.com',
    title: TWITTER,
    image: dashboardImg,
    li: '',
    icons: 'fab fa-twitter text-xl text-primary'
  },
  {
    id: 2,
    path: 'https://facebook.com',
    title: FACEBOOK,
    image: applicationsImg,
    li: 'ml-5',
    icons: 'fab fa-facebook text-xl text-blue-600'
  },
  {
    id: 3,
    path: 'https://www.linkedin.com/company/vittas',
    title: LINKEDIN,
    image: loansImg,
    li: 'ml-5',
    icons: 'fab fa-linkedin-in text-xl text-primary'
  },
  {
    id: 4,
    path: 'https://www.youtube.com',
    title: YOUTUBE,
    image: interestTrackerImg,
    li: 'ml-5',
    icons: 'fab fa-youtube text-xl text-red-700'
  }
]

export const ADMIN_NAVBAR = [
  {
    path: '/dashboard',
    component: ''
  },
  {
    path: '/applications',
    component: ''
  },
  {
    path: '/loans',
    component: ''
  },
  {
    path: '/transactions',
    component: ''
  },
  {
    path: '/feedbacks',
    component: ''
  }
]

export const SUPER_ADMIN_NAVBAR = [
  {
    path: '/dashboard',
    component: ''
  },
  {
    path: '/profile',
    component: ''
  }
]
