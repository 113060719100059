import React, { forwardRef, memo } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'

const SearchInput = forwardRef(({ value, onChange, loading, placeholder }, ref) => {
  return (
    <div className='relative'>
      <div className='absolute top-4 left-2 pl-2'>
        <i
          className={`fa fa-search ${
            loading ? 'text-primary' : 'text-gray-400'
          } z-20 hover:text-gray-500`}
        />
      </div>
      <Input
        ref={ref}
        value={value}
        onChange={onChange}
        className='h-14 pl-10 pr-10 rounded-md z-0 focus:shadow focus:outline-none'
        placeholder={placeholder}
        autoComplete='off'
        allowClear
      />
    </div>
  )
})

SearchInput.displayName = 'SearchInput'

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  placeholder: PropTypes.string
}

SearchInput.defaultProps = {
  value: '',
  loading: false,
  placeholder: ''
}

export default memo(SearchInput)
