/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import AppTable from '../../../sharedComponents/table/AppTable'
import _debounce from 'lodash/debounce'
import { adminDisbursedLoanColumn, LOAN_APPLICATION } from './constants'
import useGetLoanStatus from './useGetLoanStatus'
import { searchArrayOrMakeCallToAPI } from '../../../utils/helpers'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'

const AdminDisbursed = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [disbursedAppData, setDisbursedAppData] = useState([])
  const { permissions } = useSelector(({ auth }) => auth.authUser)

  const loadingSearch = useSelector(({ loading }) => loading.effects.applications.searchApplication)

  const handleSearchTable = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const { disbursed: disbursedLoanData, loading } = useGetLoanStatus('disbursed')

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const handleSearch = async ({ target }) => {
    if (!target.value) {
      return setDisbursedAppData(disbursedLoanData)
    }
    const resultFound = await searchArrayOrMakeCallToAPI({
      array: disbursedLoanData,
      searchTerm: target.value,
      makeSearchCall: []
    })
    return setDisbursedAppData(resultFound)
  }

  const searchUserDebounce = _debounce(handleSearch, 400)

  useEffect(() => {
    setDisbursedAppData(disbursedLoanData)
  }, [loading])
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {LOAN_APPLICATION}
          </h3>
        </div>
        {/* <ApplicationSearch url='admin/search-approved-loan' /> */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-1 text-lg mx-4'>
          <div className='relative mt-6 border rounded-lg mr-6 ml-3 col-span-3'>
            <div className='absolute top-4 left-3 pl-2'>
              <button onCanPlay={() => searchInput.current.focus()}>
                <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />{' '}
              </button>
            </div>
            <input
              type='text'
              ref={searchInput}
              onChange={searchUserDebounce}
              className='h-14 w-full pl-14 pr-20 rounded-lg z-0 focus:shadow focus:outline-none'
              placeholder='Search by amount, business name, etc'
            />
          </div>
        </div>
        <div className='mb-6 px-4 overflow-x-auto'>
          <AppTable
            rowClassName={(record, index) => (record.has_loan_ended > 0 ? 'loan-table-row' : '')}
            onRow={record => {
              return {
                onClick: e => {
                  if (!record.approved_loan_id) {
                    e.preventDefault()
                    notification.error({
                      message: 'Invalid loan Id'
                    })
                  }
                } // click row
              }
            }}
            columns={adminDisbursedLoanColumn(permissions, {
              searchInput,
              handleSearch: handleSearchTable,
              handleReset,
              setSearchText,
              searchedColumn,
              searchText,
              setSearchedColumn
            })}
            tableData={disbursedAppData?.filter(item => Boolean(item.approved_loan_id))}
            loading={loading || loadingSearch}
          />
        </div>
      </main>
    </DashboardLayout>
  )
}
export default AdminDisbursed
